<template>
  <div>
    <div class="fs-section detail mt-2">
      <div class="fs-inr pack-inr">
        <div class="fs-txt font-48">
          <h2>{{ $t("ITEL SHOP") }}<div class="d-block d-lg-none m-0 p-0 float-right"><a href="/itshop/tat-ca" class="text-primary">Xem tất cả</a></div></h2>
          <p>{{ $t("Tiện ích Max đỉnh, Shopping Max mê") }}</p>
        </div>
      </div>
      <div class="banner-slider swiper-container mb-3">
        <swiper :slides-per-view="1" :space-between="50">
          <swiper-slide>
            <a href="/open-credit-card-vpbank"
              ><img
                loading="lazy"
                alt="Banner"
                class="swiper-image"
                src="../../assets/images/DonateMoneyBg.png"
              />
            </a>
          </swiper-slide>
        </swiper>
      </div>
      <div class="fs-inr pack-inr">
        <div class="row filter">
          <a class="col-3 col-lg-2" href="/itshop/tai-chinh-bao-hiem">
            <div class="card p-1 p-lg-2 filter" :class="$route.params.slug=='tai-chinh-bao-hiem'?'selected':''">
              <img class="m-0 m-lg-2" v-if="$route.params.slug=='tai-chinh-bao-hiem'" src="@/assets/images/shop/finance_detail_2.svg"/>
              <img class="m-0 m-lg-2" v-else src="@/assets/images/shop/finance_detail_1.svg"/>
              <h2>Tài chính - Bảo hiểm</h2>
            </div>
          </a>
          <a class="col-3 col-lg-2" href="/itshop/du-lich">
            <div class="card p-1 p-lg-2 filter" :class="$route.params.slug=='du-lich'?'selected':''">
              <img class="m-0 m-lg-2" v-if="$route.params.slug=='du-lich'" src="@/assets/images/shop/travel_detail_2.svg"/>
              <img class="m-0 m-lg-2" v-else src="@/assets/images/shop/travel_detail_1.svg"/>
              <h2>Du lịch đi lại</h2>
            </div>
          </a>
          <a class="col-3 col-lg-2" href="/itshop/mua-sam">
            <div class="card p-1 p-lg-2 filter" :class="$route.params.slug=='mua-sam'?'selected':''">
              <img class="m-0 m-lg-2" v-if="$route.params.slug=='mua-sam'" src="@/assets/images/shop/shopping_detail_2.svg"/>
              <img class="m-0 m-lg-2" v-else src="@/assets/images/shop/shopping_detail_1.svg"/>
              <h2>Mua sắm</h2>
            </div>
          </a>
          <a class="col-3 col-lg-2" href="/itshop/an-uong">
            <div class="card p-1 p-lg-2 filter" :class="$route.params.slug=='an-uong'?'selected':''">
              <img class="m-0 m-lg-2" v-if="$route.params.slug=='an-uong'" src="@/assets/images/shop/food_detail_2.svg"/>
              <img class="m-0 m-lg-2" v-else src="@/assets/images/shop/food_detail_1.svg"/>
              <h2>Ăn uống</h2>
            </div>
          </a>
          <div class="col-lg-4 text-all d-none d-lg-block text-right">
            <a href="/itshop/tat-ca" class="text-primary">Xem tất cả</a>
          </div>
        </div>
      </div>
    </div>
    <div class="fs-section detail1 fs-shop">
      <div class="fs-inr pack-inr product">
        <div class="row">
          <div class="col-6 col-lg-4 col-md-6" v-for="i in products.filter(i=>i.cat==$route.params.slug||$route.params.slug=='tat-ca')" :key="i.title">
            <div class="card p-0" role="button" @click="actionHandle(i)">
              <div class="card-body">
                  <img :src="Img(i.img)" :class="`d-none d-lg-block ${i.fullImage ? 'w-100 h-100 ' : ''}`" :style="i.fullImage ? 'max-width: unset' : ''"/>
                  <img :src="Img(i.img_mobile)" class="d-block d-lg-none" />
                </div>
              <div class="card-footer border-0 pt-0 mt-0 text-dark">{{ i.title }}</div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    <b-modal size="xl" body-class="p-4" hide-header hide-footer id="modal-ocb" title="Thông báo!">
      <div class="row">
        <div class="col-lg-7 col-12">
          <img width="100%" src="@/assets/images/shop/ocb2.png"/>
          <h1 class="page-item text-center pt-2 pb-1">MỞ OMNI - NHẬN TIỀN TỨC THÌ</h1>
        </div>
        <div class="ocb col-lg-5 col-12">
          <h1 class="border-bottom mb-1 page-item pb-1 font-weight-bold">THÔNG TIN CHƯƠNG TRÌNH</h1>
          <div class="text" style="max-height:460px; overflow:auto">
            <b>1. Tên chương trình</b>: MỞ OMNI – NHẬN TIỀN TỨC THÌ<br>
            <b>2. Thời gian khuyến mại</b>: Chương trình triển khai từ ngày 01/12/2022 đến ngày 31/12/2022 hoặc đến khi sử dụng hết ngân sách nhận thưởng.<br>
            <b>3. Địa bàn (phạm vi) khuyến mại</b>: Toàn quốc.<br>
            <b>4. Đối tượng áp dụng</b>: Khách hàng chưa có tài khoản thanh toán tại OCB.<br>
            <b>5. Nội dung chi tiết thể lệ chương trình</b>:<br>
            <div class="ml-2 text">
              <b>5.1 Điều kiện tham gia</b>:<br>
              <ul>
                <li>Chương trình “Mở OMNI - Nhận tiền tức thì” là chương trình liên kết giữa CTCP Viễn Thông Di Động Đông Dương Telecom (ITEL) với Ngân hàng TMCP Phương Đông (OCB), các ưu đãi đặc biệt dành cho Khách hàng cá nhân mới chưa có thông tin tại OCB.</li>
                <li>Các bước tham gia chương trình:</li>
              </ul>
              <b>Bước 1</b>: Khách hàng tải và cài đặt ứng dụng Ngân hàng số OCB OMNI theo 3 cách sau: <br>
              <div class="pl-2">
                Cách 1: Click vào đường link: <a href="https://ocbomni.onelink.me/RE8p/iTel"><b>https://ocbomni.onelink.me/RE8p/iTel</b></a><br>
                Cách 2: Click vào hưỡng dẫn trên bài viết giới thiệu về chương trình.<br>
                Cách 3: Scan mã QR code:<br>
                <img src="@/assets/images/services/ocb_qr.png" width="50%"/><br>
                <b>Bước 2</b>: Thực hiện đăng ký tài khoản thanh toán tại OCB trên ứng dụng OCB OMNI bằng công nghệ định danh điện tử (eKYC) 100% online.<br>
                <b>Bước 3</b>: Sau khi hoàn tất đăng ký, đăng nhập OCB OMNI và thực hiện ít nhất 1 (một) giao dịch thanh toán tối thiểu 20.000 VNĐ như thanh toán hóa đơn, nạp tiền điện thoại…hoặc giao dịch chuyển khoản tối thiểu <b>100.000 VNĐ</b> trong vòng 30 ngày kể từ ngày đăng ký tài khoản thành công.<br>
                <b>Bước 4</b>: Click vào đường link: <a href="https://myitel.onelink.me/1Wbg/iTelShopOCB"><b>https://myitel.onelink.me/1Wbg/iTelShopOCB</b></a> (với những Khách hàng chưa có My iTel) Hoặc quét mã QR-code và thực hiện đăng ký thành công My iTel.<br>
                <img src="@/assets/images/shop/itel_qr.png" width="50%"/><br>
              </div>
              <b>5.2 Cách thức trao thưởng</b><br>
              Khách hàng sẽ nhận được 100.000 VNĐ (một trăm nghìn đồng) vào tài khoản OCB OMNI vừa mở trong vòng 7 ngày đến 30 ngày làm việc sau khi khách hàng hoàn tất mở mới và thực hiện giao dịch hợp lệ thành công trên ứng dụng Ngân hàng số OCB OMNI.<br>
              <b>5.3 Quy định chương trình</b><br>
              <ul>
                <li>Mỗi khách hàng được tham gia chương trình 01 (một) lần và tính cho giao dịch thỏa điều kiện đầu tiên.</li>
                <li>Chương trình có thể kết thúc sớm hơn thời gian dự kiến khi hết ngân sách khuyến mại.</li>
                <li>Thời gian giải quyết khiếu nại là 30 ngày kể từ ngày chương trình kết thúc</li>
              </ul>
            </div>
            <div class="text">
              <b>6. Thông tin liên hệ:</b>
              Mọi thắc mắc liên quan đến chương trình, Quý Khách vui lòng liên hệ Trung tâm dịch vụ Khách hàng OCB:
              <ul>
                <li>Hội sở chính: 41&45 Lê Duẩn, Phường Bến Nghé, Quận 1, Tp.HCM</li>
                <li>Hotline: 1800 6678 (Miễn phí 24/7)</li>
                <li>Email: dvkh@ocb.com.vn</li>
              </ul>
            </div>
          </div>
          <a href="https://ocbomni.onelink.me/RE8p/iTel" class="btn btn-primary btn-sm w-100 mt-2">Tiếp tục</a>
        </div>
      </div>
    </b-modal>
    <b-modal body-class="p-2" modal-class="mt-4" hide-header hide-footer id="modal-dagoras" title="Thông báo!">
      <div class="row" v-if="currentDagoras" >
        <div class="col-12" v-if="currentDagoras.title!='Vntrip' && currentDagoras.title!='Mở tài khoản OCB' && currentDagoras.title!='Săn mã Highlands coffee'">
          <h5 class="modal-title">
            <img src="@/assets/images/logo/logo.svg" v-if="currentDagoras.partner!='dagoras'" style="width:30%" class="mb-4 border-right border-2 pr-2" alt="icon-notify">
            <img :src="Img(currentDagoras.img)" style="width:40%" class="ml-2 mb-4" alt="icon-notify">
          </h5>
          <p class="text vietlott-info">1. Bạn sẽ được chuyển hướng sang màn hình của  {{currentDagoras.name }}</p>
          <p class="text vietlott-info">2. {{currentDagoras.info }}</p>
          <p class="text vietlott-info">3. Lưu ý: Thông tin chi tiết về điều kiện và điều khoản dịch vụ vui lòng xem<b role="button" class="text-primary" @click="$bvModal.show('modal-agree')"> tại đây</b>.</p>
        </div>
        <div class="col-12" v-else-if="currentDagoras.title=='Săn mã Highlands coffee'">
          <h5 class="modal-title">
            <img src="@/assets/images/logo/logo.svg" v-if="currentDagoras.partner!='dagoras'" style="width:30%" class="mb-4 border-right border-2 pr-2" alt="icon-notify">
            <img :src="Img(currentDagoras.img)" style="width:40%" class="ml-2 mb-4" alt="icon-notify">
          </h5>
          <h6 class="text vietlott-info text-center"><b>Hướng dẫn sử dụng</b></h6>
          <p class="text vietlott-info">1. Bấm “Tiếp tục” và chọn “Nhận mã ưu đãi ngay” để lấy mã.</p>
          <p class="text vietlott-info">2. Chụp màn hình hoặc lưu lại mã ưu đãi để sử dụng.</p>
          <p class="text vietlott-info">3. Cung cấp mã ưu đãi khi thanh toán tại hệ thống cửa hàng Highlands Coffee được áp dụng (chi tiết xem thêm tại mục “Điều kiện áp dụng” tại màn hình nhận mã).</p>
        </div>
        <div class="col-12" v-else>
          <h5 class="modal-title">
            <img src="@/assets/images/logo/logo.svg" v-if="currentDagoras.partner!='dagoras'" style="width:30%" class="mb-4 border-right border-2 pr-2" alt="icon-notify">
            <img :src="Img(currentDagoras.img)" style="width:40%" class="ml-2 mb-4" alt="icon-notify">
          </h5>
          <p class="text vietlott-info" v-html="currentDagoras.info"></p>
          <p class="text vietlott-info" v-if="currentDagoras.title=='Mở tài khoản OCB'">4. Lưu ý: Thông tin chi tiết về điều kiện và điều khoản dịch vụ vui lòng xem<b role="button" class="text-primary" @click="$bvModal.show('modal-agree')"> tại đây</b>.</p>
        </div>
        <div class="col-12 text-center pt-2 mt-1 mb-3">
          <button class="fs-but1-white mr-1" @click="$bvModal.hide('modal-dagoras')">Đóng</button>
          <a class="fs-but1" :href="last_url">{{ currentDagoras.btn_text }}</a>
        </div>
      </div>
    </b-modal>
    <b-modal body-class="p-2" hide-header hide-footer id="modal-agree" title="Thông báo!">
      <div class="row" v-if="currentDagoras">
        <div class="col-12">
          <h5 class="modal-title mb-2 border-bottom">Điều Khoản {{ currentDagoras.name }}</h5>
          <p class="text vietlott-info" v-html="currentDagoras.agree"/>
        </div>
        <div class="col-12 text-center pt-2 mt-1">
          <button class="fs-but1-white mr-1" @click="$bvModal.hide('modal-agree')">Đóng</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { Navigation, Pagination } from "swiper";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import useJwt from "@/auth/jwt/useJwt";
import {
  BFormInput
} from "bootstrap-vue";
// Import Swiper styles
import "swiper/swiper-bundle.css";
import M from "minimatch";
SwiperCore.use([Navigation, Pagination]);
function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images["assets/images/shop/partner/" + item.replace("./", "")] = r(item);
  });
  return images;
}
const images = importAll(
  require.context("@/assets/images/shop/partner/", false, /.(png)$/)
);


export default {
  components: {
    Swiper,
    SwiperSlide,
    BFormInput
  },
  data() {
    return {
      iframeSrc: '',
      phone:'',
      otp:'',
      products:[
        // {code:'tima',cat:'tai-chinh-bao-hiem',partner:'tima',img:'tima_web.png',img_mobile:'tima.png',title:'Đăng ký vay nhanh ngay tại Tima', url:'/tima',
        // btn_text:'',
        // info:'',
        //   agree:"",
        // },
        {code:'ocb',cat:'tai-chinh-bao-hiem',partner:'dagoras',img:'ocb_web.png',img_mobile:'ocb.png',title:'Mở tài khoản OCB', url:'https://ocbomni.onelink.me/RE8p/iTel',
        btn_text:'Mở thẻ ngay',
        info:'1. Đây là chương trình liên kết Mở tài khoản ngân hàng giữa OCB và Itel. <br>'+
            '2. Bạn sẽ được chuyển hướng sang màn hình tải ứng dụng Ngân hàng số OCB OMNI để mở tài khoản của OCB. <br>'+
            '3. OCB (Ngân hàng TMCP Phương Đông) cung cấp các sản phẩm/dịch vụ tài chính - ngân hàng giúp khách hàng và đối tác một cách minh bạch, thuận tiện, hiệu quả. <br>',
          agree:"1. Ngân hàng TMCP Phương Đông (OCB) là đơn vị có tư cách pháp nhân độc lập, không phải công ty con, chi nhánh hay đơn vị trực thuộc của iTel. <br>"+
            "2. Ứng dụng Ngân hàng số OCB OMNI do OCB độc lập phát triển và cung cấp cho khách hàng, không phải là một phần hay bộ phận của ứng dụng My iTel. <br>"+
            "3. Đối với các vấn đề phát sinh khiếu nại của khách hàng, OCB có trách nhiệm giải quyết.<br>"+
            "4. Quý khách nếu cần hỗ trợ, vui lòng liên hệ hotline OCB: 1800 6678 (Miễn phí 24/7)",
        },
        /*{cat:'tai-chinh-bao-hiem',partner:'dagoras',img:'mb_web.png',img_mobile:'mb.png',title:'Mở tài khoản ngân hàng Quân đội MB', url:'',
          name:'MB Bank',
          info:'MBBank (Ngân hàng Thương mại Cổ phần Quân Đội) cung cấp sản phẩm/dịch vụ tài chính - ngân hàng giúp khách hàng và đối tác một cách minh bạch, thuận tiện, hiệu quả.',
          agree:'Như đã trao đổi bỏ nhà cung cấp này khỏi danh mục'
        },*/
        {code:'vp',cat:'tai-chinh-bao-hiem',img:'vp_web_2.png', fullImage: true, img_mobile:'vp_1.png',title:'Mở thẻ tín dụng VPbank', url:'/open-credit-card-vpbank'},
        {code:'vib',cat:'tai-chinh-bao-hiem',partner:'dagoras',img:'vib_web.png',img_mobile:'vib.png',title:'Mở thẻ tín dụng VIB', url:'https://partner.dagoras.io/itel/vib',
          name:'VIB',
          info:'VIB (Ngân hàng Thương mại Cổ phần Quốc tế) cung cấp sản phẩm/dịch vụ tài chính - ngân hàng giúp khách hàng và đối tác một cách minh bạch, thuận tiện, hiệu quả. ',
          agree:"1. Ngân hàng Thương mại Cổ phần Quốc tế (VIB) là đơn vị có tư cách pháp nhân độc lập, không phải công ty con, chi nhánh hay đơn vị trực thuộc của Công ty CP Viễn thông di động Đông Dương Telecom (ITEL)."+
              "<br>2. Chương trình về mở Thẻ tín dụng là sản phẩm dịch vụ của VIB, do VIB độc lập phát triển và cung cấp cho khách hàng, không do iTel sở hữu."+
              "<br>3. Đối với các vấn đề phát sinh vướng mắc và phát sinh khiếu nại (nếu có) của khách hàng, VIB có trách nhiệm giải quyết."+
              "<br>4. Quý khách nếu cần hỗ trợ vui lòng liên hệ Hotline VIB: 1800 8180 (Miễn phí)",
              btn_text:'Mở thẻ ngay',
        },
        {code:'mbal',cat:'tai-chinh-bao-hiem',partner:'dagoras',img:'mbal_web.png',img_mobile:'mbal.png',title:'Mua bảo hiểm nhân thọ MB Ageas life', url:'https://partner.dagoras.io/itel/mbal',
          name:'MBAL Ageas Life',
          info:'Bảo hiểm nhân thọ MB Ageas Life được thành lập giữa Ngân hàng TMCP Quân đội (Việt Nam) với Tập đoàn Bảo hiểm quốc tế Ageas (Bỉ) và Công ty BHNT Muang Thai Life (Thái Lan), mang đến những dịch vụ tốt nhất thị trường, đồng hành cùng bạn qua những thăng trầm cuộc sống. ',
          agree:"1. Công ty TNHH Bảo hiểm Nhân thọ MB Ageas là đơn vị có tư cách pháp nhân độc lập, không phải công ty con, chi nhánh hay đơn vị trực thuộc của Công ty CP Viễn thông di động Đông Dương Telecom (ITEL)."+
                    "<br>2. Các sản phẩm bảo hiểm nhân thọ MB Ageas Life do Công ty Bảo hiểm Nhân thọ MB Ageas độc lập phát triển và cung cấp cho khách hàng, không do iTel sở hữu."+
                    "<br>3.Đối với các vấn đề phát sinh vướng mắc và phát sinh khiếu nại (nếu có) của khách hàng, MB Ageas Life có trách nhiệm giải quyết."+
                    "<br>4. Quý khách nếu cần hỗ trợ vui lòng liên hệ Hotline MB Ageas Life: 024 2229 8888 (Cước phí cố định)",
                    btn_text:'Đăng ký ngay',
        },
        {code:'bsh',cat:'tai-chinh-bao-hiem',partner:'dagoras',img:'bsh_web.png',img_mobile:'bsh.png',title:'Mua bảo hiểm BSH', url:'https://partner.dagoras.io/itel/bsh',
          name:'Bảo hiểm BSH',
          info:'BSH (TỔNG CÔNG TY CỔ PHẦN BẢO HIỂM SÀI GÒN - HÀ NỘI) đem đến các sản phẩm bảo hiểm toàn diện, thiết yếu đáp ứng tối đa nhu cầu của khách hàng.',
          agree:"1. Tổng Công ty Cổ phần Bảo hiểm Sài Gòn - Hà Nội là đơn vị có tư cách pháp nhân độc lập, không phải là công ty con, chi nhánh hay đơn vị trực thuộc của Công ty CP Viễn thông di động Đông Dương Telecom (ITEL)."+
                "<br>2. Các sản phẩm Bảo hiểm là sản phẩm dịch vụ của Tổng Công ty Cổ phần Bảo hiểm Sài Gòn - Hà Nội độc lập phát triển và cung cấp cho khách hàng,  không do iTel sở hữu."+
                "<br>3. Đối với các vấn đề phát sinh vướng mắc và phát sinh khiếu nại (nếu có) của khách hàng, BSH có trách nhiệm giải quyết."+
                "<br>4. Quý khách nếu cần hỗ trợ vui lòng liên hệ Hotline BSH: 024 3793 1111 (Cước phí cố định)",
                btn_text:'Đăng ký ngay',
        },
        
        {code:'vietlott',cat:'mua-sam',img:'vietlott.png',img_mobile:'vietlott.png',title:'Vé số Vietlott', url:'/vietlott'},

        {code:'vntrip',cat:'du-lich',img:'vntrip_web.png',img_mobile:'vntrip.png',title:'Du lịch', url:'/vntrip'},
        {code:'vexere',cat:'du-lich',img:'vexere_web.png',img_mobile:'vexere.png',title:'Mua vé xe rẻ', url:'/vexere'},
        {code:'abtrip',cat:'du-lich',img:'abtrip_web.png',img_mobile:'abtrip.png',title:'Vé máy bay online', url:'/abtrip'},

        {code:'shopee',cat:'mua-sam',partner:'dagoras',img:'shopee_web.png',img_mobile:'shopee.png',title:'Sàn thương mại điện tử Shopee', url:'https://partner.dagoras.io/itel/shopee',
          name:'Shopee',
          info:'Shopee là sàn thương mại điện tử hàng đầu về bán lẻ, cung cấp những sản phẩm chính hiệu và hướng tới trải nghiệm mua sắm tuyệt vời và nhanh chóng đến tất cả khách hàng. ',
          agree:"1. Công ty TNHH Shopee là đơn vị có tư cách pháp nhân độc lập, không phải công ty con, chi nhánh hay đơn vị trực thuộc của Công ty CP Viễn thông di động Đông Dương Telecom (ITEL)."+
              "<br>2. Sàn thương mại điện tử Shopee do Công ty TNHH Shopee độc lập phát triển và cung cấp dịch vụ cho khách hàng, không do iTel sở hữu."+
              "<br>3. Đối với các vấn đề phát sinh vướng mắc và phát sinh khiếu nại (nếu có) của khách hàng, Shopee có trách nhiệm giải quyết."+
              "<br>4. Quý khách nếu cần hỗ trợ vui lòng liên hệ Hotline Shopee: 1900 1221 (1000đ/phút)",
              btn_text:'Mua ngay',
        },
        {code:'tiki',cat:'mua-sam',partner:'dagoras',img:'tiki_web.png',img_mobile:'tiki.png',title:'Sàn thương mại điện tử Tiki', url:'https://partner.dagoras.io/itel/tiki',
          name:'Tiki',
          info:'Tiki là sàn thương mại điện tử hàng đầu về bán lẻ, cung cấp những sản phẩm chính hiệu và tới trải nghiệm mua sắm tuyệt vời và nhanh chóng đến tất cả khách hàng. ',
          agree:"1. Công ty Cổ phần TIKI là đơn vị có tư cách pháp nhân độc lập, không phải công ty con, chi nhánh hay đơn vị trực thuộc của Công ty CP Viễn thông di động Đông Dương Telecom (ITEL)."+
            "<br>2. Sàn thương mại điện tử Tiki do Công ty Cổ phần TIKI độc lập phát triển và cung cấp cho khách hàng, không do iTel sở hữu."+
            "<br>3. Đối với các vấn đề phát sinh vướng mắc và phát sinh khiếu nại (nếu có) của khách hàng, Tiki có trách nhiệm giải quyết."+
            "<br>4. Quý khách nếu cần hỗ trợ vui lòng liên hệ Hotline Tiki: 1900 6035 (1000đ/phút)",
            btn_text:'Mua ngay',
        },

        {code:'concung',cat:'mua-sam',partner:'dagoras',img:'concung_web.png',img_mobile:'concung.png',title:'Đồ mẹ và bé Concung', url:'https://partner.dagoras.io/itel/concung',
          name:'Con cưng',
          info:'Công ty Cổ phần Con Cưng là công ty tiên phong tại Việt Nam chuyên về ngành hàng dành riêng cho trẻ em. Con Cưng tập trung phát triển các hệ thống chuỗi bán lẻ cho mẹ bầu & em bé, đồng thời nghiên cứu và cho ra đời các sản phẩm an toàn, chất lượng, giá thành hợp lý dành riêng cho trẻ em. ',
          agree:"1. Công ty Cổ phần Con Cưng là đơn vị  có tư cách pháp nhân độc lập, không phải công ty con, chi nhánh hay đơn vị trực thuộc của Công ty CP Viễn thông di động Đông Dương Telecom (ITEL)."+
            "<br>2. Các sản phẩm dịch vụ do Công ty Cổ phần Con Cưng độc lập phát triển và cung cấp cho khách hàng, không do iTel sở hữu."+
            "<br>3. Đối với các vấn đề phát sinh vướng mắc và phát sinh khiếu nại (nếu có) của khách hàng, Con Cưng có trách nhiệm giải quyết."+
            "<br>4. Quý khách nếu cần hỗ trợ vui lòng liên hệ Hotline Con Cưng: 1800 6609 (Miễn phí)",
            btn_text:'Mua ngay',
        },
        {code:'routine',cat:'mua-sam',partner:'dagoras',img:'routine_web.png',img_mobile:'routine.png',title:'Thời trang Routine', url:'https://partner.dagoras.io/itel/routine',
          name:'Thời trang Routine',
          info: 'Routine là thương hiệu quần áo, phụ kiện thời trang hướng tới việc trở thành thói quen, lực chọn hàng ngày trong mọi tình huống. ',
          agree:"1. Công ty TNHH Routine Việt Nam là đơn vị có tư cách pháp nhân độc lập, không phải công ty con, chi nhánh hay đơn vị trực thuộc của Công ty CP Viễn thông di động Đông Dương Telecom (ITEL)."+
              "<br>2. Các sản phẩm quần áo, phụ kiện thời trang Routine do Công ty TNHH Routine Việt Nam độc lập phát triển và cung cấp cho khách hàng, không do iTel sở hữu."+
              "<br>3. Đối với các vấn đề phát sinh vướng mắc và phát sinh khiếu nại (nếu có) của khách hàng, Routine có trách nhiệm giải quyết."+
              "<br>4. Quý khách nếu cần hỗ trợ vui lòng liên hệ Hotline Routine: 1900 636591 (1000đ/phút)",
              btn_text:'Mua ngay',
        },

        {code:'highland',cat:'an-uong',partner:'dagoras',img:'highland_web.png',img_mobile:'highland.png',title:'Săn mã Highlands coffee', url:'https://partner.dagoras.io/itel/highlands',
          name:'Highlands coffee',
          info:'Highlands Coffee là chuỗi cửa hàng kinh doanh cà phê và các loại đồ ăn nhanh của Việt Nam, vận hành và sở hữu bởi Công ty Cổ phần Dịch vụ Cà phê Cao Nguyên. ',
          agree:'1. Công ty Cổ phần Dịch vụ Cà phê Cao Nguyên là đơn vị có tư cách pháp nhân độc lập, không phải công ty con, chi nhánh hay đơn vị trực thuộc của Công ty CP Viễn thông di động Đông Dương Telecom (ITEL).'+
              '<br>2.Các sản phẩm đồ uống, đồ ăn nhanh Highlands Coffee là sản phẩm dịch vụ của Công ty Cổ phần Dịch vụ Cà phê Cao Nguyên độc lập phát triển và cung cấp cho khách hàng, không do iTel sở hữu.'+
              '<br>3. Đối với các vấn đề phát sinh vướng mắc và phát sinh khiếu nại (nếu có) của khách hàng, Highlands Coffee có trách nhiệm giải quyết.'+
              '<br>4. Quý khách nếu cần hỗ trợ vui lòng liên hệ Hotline Highlands Coffee: 19001755 (1500đ/phút)',
              btn_text:'Tiếp tục',

        }
      ],
      vntrip: "",
      currentDagoras: null,
      last_url: ''
    };
  },
  watch: {
    $route(to, from) {
      console.log('a');
      this.$bvModal.show('modal-vietlott');
    },
  },
  created() {

  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
  mounted() {
    console.log(images)
    useJwt.get("vntrip/getlink/flight").then((response) => { this.vntrip = response.data.result.replace("flight", "") });
  },
  methods: {
    async actionHandle(i){
      console.log(i);
      this.currentDagoras = i;
      
      if (i.url=='/ocb'){
        this.$bvModal.show('modal-ocb')
      }else if (i.partner=='dagoras'){
        var resp = await useJwt.get("dagoras/getlink", {params:{url:i.url,code:i.code}});
        console.log(resp.data.result);
        this.last_url = resp.data.result;
        this.$bvModal.show('modal-dagoras')
      }else{
        document.location= i.url;
      }
    },
    Img(pic) {
      return images['assets/images/shop/partner/'+pic];
    },
    getVietlottView(){
      if (!/^([0-9]{10})$/.test(this.phone)) {
        this.$toast.error("Vui lòng nhập đúng số thuê bao di động", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      if (!/^([0-9]{6})$/.test(this.otp)) {
        this.$toast.error("Mã OTP gồm 6 chũ số", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      useJwt.get("vietlot/getlink", {params:{phone:this.phone,tran_id:'VL-'+Date.now(),otp:this.otp}})
        .then((response) => {
          console.log(response.data.result)
          this.$bvModal.hide('modal-vietlott');
          
          // window.open(response.data.result);
          window.location.assign(response.data.result)
        }).catch((err) => {
          this.loading(false);
          this.$toast.error("Vui lòng kiểm tra số điện thoại và mã OTP", {
            icon: true,
            closeButton: "button",
          });
        });;
    },
    async sendOTP() {
      if (this.otp.length > 0) {
        this.otp = "";
      }
      if (!/^([0-9]{10})$/.test(this.phone)) {
        this.$toast.error("Vui lòng nhập đúng số thuê bao di động", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      this.loading(true);
      useJwt.get("otp2/" + this.phone).then((response) => {
          this.$toast.info("Vui lòng kiểm tra tin nhắn để lấy mã OTP", {
            icon: true,
            closeButton: "button",
          });
          this.isDisableOTPText = false;
          this.loading(false);
        }).catch((err) => {
          this.loading(false);
          this.$toast.error(err.response.data.message, {
            icon: true,
            closeButton: "button",
          });
        });
    },
  },
};
</script>
<style scoped>
.swiper-image{
  width: 100%;
  cursor: pointer;
}
</style>
<style>
.b2c-page-bg {
  background: rgba(242, 242, 242, 1);
}
.b2c-bg {
  background: rgba(237, 31, 36, 1);
  padding-bottom: 32px;
}
.b2c-bg-top {
  background: rgba(237, 31, 36, 1);
  display: flex;
  margin-bottom: 0px;
}
.b2c-top-left::before {
  content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyOCAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3IDIwLjE0MjhMMTEgMTQuMTQyOEwxNyA4LjE0MjgyIiBzdHJva2U9IndoaXRlIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
}
.b2c-top-left {
  height: 44px;
  padding: 8px 14px;
  width: 20%;
}
.b2c-pc-header {
  max-width: 122.4rem;
  margin-left: auto;
  margin-right: auto;
  height: 70px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  min-height: 500px;
}
.b2c-pc-header {
  display: flex;
}
.b2c-pc-header h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 69px;
  color: #424242;
  width: 50%;
}
.b2c-pc-header .nav{
  background-image:none !important
}
.b2c-top-center {
  width: 80%;
  height: 22px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  padding-top: 7px;
}
.b2c-top-right {
  width: 20%;
}
.modal-title{
  padding-top: 35px;
  font-size: 30px !important;
  color: '#373737' !important;
  font-weight: 700;
  text-align: center;
}
.vietlott-info{
  line-height: 1.5;
  padding: 10px 30px
}
.fs-but1 {
	padding:0.8rem 2.55rem;
	background-color:#ff2424;
	/*text-transform:uppercase;*/
	font-size:1.8rem;
	font-weight:500;
	color:#fff !important;
	-webkit-border-radius:2rem;
	border-radius:2rem;
	border:2px solid rgba(255, 36, 36, 0);
	cursor:pointer;
	text-align:center;
}
.fs-but1-white {
	padding:0.8rem 4.55rem;
	background-color:#fff;
	font-size:1.8rem;
	font-weight:500;
	color:#ff2424 !important;
	-webkit-border-radius:2rem;
	border-radius:2rem;
	border:2px solid rgba(255, 36, 36, 1);
	cursor:pointer;
	text-align:center;
}
.fs-shop.detail,.fs-shop.detail1 {
	padding: 2rem 0 9rem 0;
	background-color: #F7F7F7;
	overflow: hidden;
  margin:0;
}
.fs-section.detail .filter h2{
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
}
.fs-section.detail .filter.selected h2{
  color: #fff;
}
.fs-section.detail .filter.selected{
  background: linear-gradient(224.68deg, #F48181 9.52%, #F95454 28.68%, #E21A1A 95.11%);
}
.fs-section.detail .filter img{
  height: 50px;
}
@media screen and (max-width: 768px) {
  .fs-section.detail .filter img{
    height: 50px;
  }
  .fs-section.detail .filter h2{
    font-size: 11px;
  }
  .fs-section.detail .card{
    height: 94px !important;
  }
  .fs-section.detail .col-3{
    padding: 0 0.5rem !important
  }
  .fs-section.detail .row.filter{
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
}
.fs-section .text-all{
  font-weight: 700;
  font-size: 22px;
  line-height: 100px;
  /* identical to box height */
  color: #F22727;
}
@media screen and (max-width: 719px){
  .fs-shop.detail1 .card-body img {
    max-height: none;
    max-width: none;
    width: 100%;
  }
  .fs-shop.detail1 .card-body{
    height: auto;
  }
  .fs-shop.detail1 .d-block{
    margin-bottom: 0;
    padding-top:0;
  }
  .product .card-footer{
    min-height: 41px;
  }
}
</style>